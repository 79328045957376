import React from 'react';

import { Container, makeStyles } from '@material-ui/core';

import { HowItWorks } from './HowItWorks';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { BodyFeature } from '../Industry/Features/BodyFeature';

const useStyles = makeStyles((theme) => ({
	featureCont: {
		padding: '2rem 0 8rem 0',
		// [theme.breakpoints.down('md')]: {
		// 	padding: '16rem 0',
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	padding: '8rem 0',
		// },
	},
}));

export const RoutingEngineBody = ({
	introHeader,
	introBody,
	howItWorks,
	featureSection,
	featureBackground,
}) => {
	const classes = useStyles();
	return (
		<>
			<HowItWorks
				header={introHeader}
				subheader={introBody}
				howItWorks={howItWorks}
			/>
			<div
				style={{
					backgroundImage: `url(${featureBackground?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.featureCont}>
				<Container>
					{featureSection.map((feature, index) => (
						<BodyFeature
							feature={feature}
							key={index}
							index={1}
							accentColor='#2A7ABC'
							routing
						/>
					))}
				</Container>
			</div>
			<WaveUpSVG fill='#FFFFFF' />
		</>
	);
};
