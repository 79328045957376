import React, { useRef, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { CustomerLogos } from '../components/CustomerLogos';
import { RoutingEngineBody } from '../components/RoutingEngine/RoutingEngineBody';
import { Testimonials } from '../components/Testimonials';
import { Hero } from '../components/RoutingEngine/Hero';
import { HeroModal } from '../components/RoutingEngine/Hero/HeroModal';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
	testimonialCont: {
		background: 'transparent',
		// padding: '2rem 0',
		zIndex: 1,
		position: 'relative',
		marginTop: '-5rem',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-3rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-2rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-1rem',
		},
	},
}));

const RoutingEnginePage = ({ data, location }) => {
	const routingEngine = data.routingEngine.edges[0].node;
	const classes = useStyles();
	const inputRef = useRef();
	const formRef = useRef(null);

	const [heroModalOpen, setHeroModalOpen] = useState(false);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		hero,
		customerLogos,
		customerLogosHeader,
		formBgImage,
		marketoId,
		pardotUrl,
		contactForm,
		resourceTitle,
		resources,
		_rawResourceBody,
		testimonials,
		testimonialTitle,
		introHeader,
		introBody,
		howItWorks,
		featureSection,
		featureBackground,
	} = routingEngine;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				setHeroModalOpen={setHeroModalOpen}
				setFormModalOpen={setFormModalOpen}
			/>
			<RoutingEngineBody
				introHeader={introHeader}
				introBody={introBody}
				howItWorks={howItWorks}
				featureSection={featureSection}
				featureBackground={featureBackground}
			/>
			<div className={classes.testimonialCont}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonials}
					formRef={formRef}
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<WaveDownSVG fill='#FFFFFF' />

			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			<WaveUpSVG fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
			<HeroModal
				open={heroModalOpen}
				setHeroModalOpen={setHeroModalOpen}
				wistiaLink={hero.wistiaLink}
			/>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
};

export const query = graphql`
	query RoutingEnginePageQuery {
		routingEngine: allSanityRoutingEngine {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						ctaText
						wistiaLink
						wistiaImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					introHeader
					introBody
					howItWorks {
						_rawContent
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 800)
							}
						}
					}
					featureBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						bodySubHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						ctaText
						# svgVariant
						thumbnailVideoUrl
						_rawBodyCopy
						ctaLink
						internalLink
					}
					testimonialTitle
					testimonials {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
					pardotUrl
					resources {
						title
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						blurb
						ctaLink
						ctaText
					}
					resourceTitle
					_rawResourceBody
				}
			}
		}
	}
`;

export default RoutingEnginePage;
