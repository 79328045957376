import React from 'react';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ListItem } from '../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	heading: {
		paddingBottom: '4rem',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
	},
	subheader: {
		lineHeight: '32px',
	},
	h2: {
		margin: '1rem 0',
	},
	h4: {
		margin: '1rem 0',
	},
	text: {
		color: theme.workwaveBlue,
		fontFamily: 'Roboto',
		fontSize: '1.25rem',
		paddingTop: '1em',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	bulletCopy: {
		color: theme.workwaveBlue,
		listStyleType: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '.5rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	inputs: {
		paddingLeft: '2rem!important',
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
  linkText: {
    color: theme.mediumDarkGray,
  },
}));

export const HowItWorks = ({ header, subheader, howItWorks }) => {
	const classes = useStyles();
	const { _rawContent, image } = howItWorks;
	return (
		<Container>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignContent='center'
				spacing={2}
				className={classes.heading}>
				<Grid item>
					<Typography variant='h2' color='primary'>
						{header}
					</Typography>
				</Grid>

				<Grid item xs={12} md={10}>
					<Typography body='body1' className={classes.subheader}>
						{subheader}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				style={{ paddingBottom: '4rem' }}
				spacing={2}
				alignItems='space-evenly'>
				<Grid
					item
					md={6}
					container
					direction='column'
					alignItems='flex-start'
					className={classes.inputs}>
					{_rawContent.map((content, index) => (
						<PortableText
							content={content}
							key={index}
							serializers={{
								h2: ({ children }) => (
									<Grid item>
										<Typography
											variant='h2'
											color='primary'
											className={classes.h2}>
											{children}
										</Typography>
									</Grid>
								),
								normal: ({ children }) => (
									<Typography variant='body1' color='primary'>
										{children}
									</Typography>
								),
								internalLink: ({ children, internalSlug }) => (
									<Link to={`/${internalSlug}`} className={classes.linkText}>
										{children}
									</Link>
								),
								h4: ({ children }) => (
									<Grid item>
										<Typography
											variant='h6'
											color='primary'
											className={classes.h4}>
											{children}
										</Typography>
									</Grid>
								),
								li: ({ children }) => (
									<Grid item>
										<ListItem>
											<li className={classes.bulletCopy}>
												<Grid container>
													<Grid item>
														<FontAwesomeIcon
															icon={['fas', 'circle-check']}
															size='lg'
															style={{
																color: '#002D5C',
																marginRight: '8px',
															}}
														/>

														{children}
													</Grid>
												</Grid>
											</li>
										</ListItem>
									</Grid>
								),
							}}
						/>
					))}
				</Grid>
				<Grid item md={6} container direction='column' alignItems='center'>
					<GatsbyImage
						image={image?.asset.gatsbyImageData}
						imgStyle={{ objectFit: 'contain' }}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
